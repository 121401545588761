import React, { CSSProperties, useEffect, useState } from 'react';
import './CallUsBanner.scss';
import { StickyBannerChunky } from 'components/Common/Header/StickyBannerChunky';
import { launchDarklyClient } from 'services/LaunchDarkly/LaunchDarklyClient';
import { Flags } from 'services/LaunchDarkly/flags';
import { StickyBannerSkinny } from 'components/Common/Header/StickyBannerSkinny';
import { SalesPhoneNumber } from '../ContactInformation';

export const CallUsBanner: React.FC = () => {
	const [isBannerSticky, setIsBannerSticky] = useState(false);
	const [bannerToShow, setBannerToShow] = useState<
		'version-one' | 'version-two'
	>('version-one');

	useEffect(() => {
		const megaMenuSearch = document.getElementById('header-search-mobile');

		const megaMenuSearchBottom =
			megaMenuSearch.offsetTop + megaMenuSearch.offsetHeight;

		const handleScroll = (): void => {
			const yOffset = window.pageYOffset;

			setIsBannerSticky(yOffset >= megaMenuSearchBottom);
		};

		window.addEventListener('scroll', handleScroll);

		launchDarklyClient
			.getFlag<'version-one' | 'version-two'>(Flags.StickyBannerVersion)
			.then(version => {
				setBannerToShow(version);

				globalThis.dataLayer.push({
					event: 'Sticky Banner Split Variant Loaded',
					eventCategory: 'Sticky Call Us Banner',
					eventAction: 'Split Variant Loaded',
					eventLabel: `${
						version === 'version-one'
							? 'Original messaging (with phone number) loaded'
							: 'Skinny banner (without phone number) loaded'
					}`,
				});
			});

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const placeholderStyles: CSSProperties = {
		height: bannerToShow === 'version-one' ? '46px' : '36px',
	};

	if (!isBannerSticky) {
		placeholderStyles.position = 'absolute';
	}

	return (
		<>
			<div id="callUsBannerPlaceholder" style={placeholderStyles}></div>

			{(!isBannerSticky || bannerToShow === 'version-one') && (
				<StickyBannerChunky
					bannerVersion={bannerToShow}
					isBannerSticky={isBannerSticky}
				>
					{bannerToShow === 'version-one' && (
						<>
							Buy quicker and easier by calling free on{' '}
							<SalesPhoneNumber />
						</>
					)}
					{bannerToShow === 'version-two' && (
						<>
							Buy quicker and easier over the phone.
							<br />
							Tap to call free.
						</>
					)}
				</StickyBannerChunky>
			)}

			{isBannerSticky && bannerToShow === 'version-two' && (
				<StickyBannerSkinny />
			)}
		</>
	);
};
