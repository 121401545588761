import React from 'react';
import './MegaMenuBannerCallUs.scss';
import { SalesPhoneNumber } from 'components/Common/ContactInformation';

export function MegaMenuBannerCallUs() {
	return (
		<SalesPhoneNumber>
			<div className="MegaMenuBannerCallUs">
				<div className="MegaMenuBannerCallUs--Number">
					<div className="MegaMenuBannerCallUs--Number--Text">
						Here to help
					</div>
					<div className="MegaMenuBannerCallUs--Number--Num">
						<SalesPhoneNumber />
					</div>
				</div>
				<div className="MegaMenuBannerCallUs--Description">
					Ring our customer sales team for expert assistance and
					exclusive deals.
				</div>
			</div>
		</SalesPhoneNumber>
	);
}
