import { ErrorBoundary } from 'components/Common/ErrorBoundary';
import { urlToRelative } from 'components/Common/url-to-relative';
import Downshift from 'downshift';
import { useDelayState } from 'hooks/use-delay-state';
import React, { useEffect, useRef, useState } from 'react';
import { FAQ, fetchSearchResults, Handset } from './api';
import { SearchAutocomplete } from './SearchAutocomplete';
import './SearchBar.scss';

function SearchBar({ bestSellers }) {
	const [query, setQuery] = useDelayState('');
	const [isFetching, setIsFetching] = useState(false);
	const [faqs, setFaqs] = useState<FAQ[]>([]);
	const [handsets, setHandsets] = useState<Handset[]>([]);
	const $abort = useRef<AbortController | null>(null);

	useEffect(() => {
		if (query.length < 2) {
			setFaqs([]);
			setHandsets([]);
			return;
		}

		const fetchData = async () => {
			setIsFetching(true);

			if ($abort.current) {
				$abort.current.abort();
				$abort.current = null;
			}

			const controller = new AbortController();
			$abort.current = controller;
			const signal = controller.signal;

			try {
				const { data, ok } = await fetchSearchResults(query, signal);
				if (ok) {
					setFaqs(data.faqs);
					setHandsets(data.handsets);
				} else {
					setFaqs([]);
					setHandsets([]);
				}
			} catch (error) {
				// Request was aborted. Stop processing.
				if (error.name === 'AbortError') {
					return;
				}

				throw error;
			} finally {
				setIsFetching(false);
			}
		};

		fetchData();
	}, [query]);

	function selectFaq(faq) {
		const queryString = query && '?search_term=' + query;
		const url =
			urlToRelative(globalThis.Router('help.index').toString()) +
			queryString +
			'#faq-item-' +
			faq.id;
		globalThis.dataLayer.push({
			event: 'globalSearchFaqSelect',
			searchTerm: query,
			labelSearchClicked: faq.question,
			eventCallback: function () {
				document.location.href = url;
				// Expand help item if on help route
				const onHelpPage =
					window.location.pathname ===
					urlToRelative(globalThis.Router('help.index').toString());
				if (onHelpPage) {
					const faqElement = document.querySelector(
						'#faq-item-' + faq.id + ' a.c-accordion-title'
					);
					faqElement && (faqElement as HTMLElement).click();
				}
			},
		});
	}
	function selectHandset(handset) {
		globalThis.dataLayer.push({
			event: 'globalSearchHandsetSelect',
			searchTerm: query,
			labelSearchClicked: handset.title,
			eventCallback: function () {
				const queryString = query && '?search_term=' + query;
				document.location.href = handset.url + queryString;
			},
		});
	}

	function selectBestSeller(navItem) {
		globalThis.dataLayer.push({
			event: 'globalSearchPopularSearchesSelect',
			searchTerm: query,
			labelSearchClicked: navItem.name,
			eventCallback: function () {
				const queryString = query && '?search_term=' + query;
				document.location.href = navItem.link + queryString;
			},
		});
	}

	return (
		<ErrorBoundary>
			<div className="o-wrapper">
				<div className="sg-inner">
					<Downshift
						itemToString={item => (item ? item.question : '')}
					>
						{props => (
							<div className="u-ta--right">
								<SearchAutocomplete
									{...props}
									faqs={faqs}
									handsets={handsets}
									query={query}
									setQuery={setQuery}
									isFetching={isFetching}
									selectFaq={selectFaq}
									selectHandset={selectHandset}
									selectBestSeller={selectBestSeller}
									bestSellers={bestSellers}
								/>
							</div>
						)}
					</Downshift>
				</div>
			</div>
		</ErrorBoundary>
	);
}

export { SearchBar };
