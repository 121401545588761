import React from 'react';

export default function CallUsV2() {
	function handleClick() {
		console.log('clicked');
	}

	return (
		<button onClick={handleClick}>
			<i className="ico ico-call"></i>
			<span className="Header--Link--Text">Call Us</span>
		</button>
	);
}
