import React, { MouseEvent, useState } from 'react';
import { Modal } from 'components/Common/Modal';
import { Button, Column, Row } from '@ao-internal/react-components';
import { useScreenSize } from 'components/Common/use-screen-size';
import './Inbound.scss';
import { launchDarklyClient } from 'services/LaunchDarkly/LaunchDarklyClient';
import { Flags } from 'services/LaunchDarkly/flags';
import { SalesPhoneNumber } from '../ContactInformation';
import { OpeningTimes } from '../OpeningTimes';

interface CallUsProps {
	useModal?: boolean;
}

export function CallUs({ useModal = false }: CallUsProps): JSX.Element {
	const [modalOpen, setModalOpen] = useState(false);

	globalThis.MPD = globalThis.MPD || {};
	const deviceType = globalThis.MPD.deviceType;
	const maxTouchPoints = globalThis.navigator.maxTouchPoints;
	const { screenSize } = useScreenSize();
	const phoneNumber: string =
		globalThis.site.sales_phone_number || '03301 65 55 55';
	//deviceCallUs(deviceType, maxTouchPoints, screenSize)

	if (
		!useModal &&
		(screenSize === 'medium' ||
			screenSize === 'small' ||
			screenSize === 'xsmall')
	) {
		useModal = true;
	}

	function onClick(e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
		e.preventDefault();
		setModalOpen(true);
		globalThis.dataLayer.push({
			event: 'GTMEvent',
			eventCategory: 'Header',
			eventAction: 'Sales Enquiries',
			eventLabel: '',
		});
	}

	function trackPhoneNumberClick(): void {
		globalThis.dataLayer.push({
			event: 'GTMEvent',
			eventCategory: 'Sales Enquiries',
			eventAction: 'Call us',
			eventLabel: phoneNumber,
		});
	}

	const CallUsLabel = ({ ...props }): JSX.Element => (
		<SalesPhoneNumber className="CallUs" {...props}>
			<div className="CallUs--Icon">
				<i className="ico ico-call"></i>
				<span className="CallUs--Label label Header--Link--Text">
					Help
				</span>
			</div>
			<div className="CallUs--Text">
				<div className="CallUs--Sales">Call Centre</div>
				<div className="CallUs--Number">
					<SalesPhoneNumber />
				</div>
				<div className="CallUs--Help">We&apos;re here to help.</div>
			</div>
		</SalesPhoneNumber>
	);

	if (!useModal) return <CallUsLabel />;

	if (launchDarklyClient.getFlag(Flags.InboundCalls)) {
		return (
			<>
				<CallUsLabel onClick={onClick} />
				<Modal
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
					title="Need Help?"
				>
					<div className="Inbound">
						<h2>
							We have a friendly UK based sales team ready to
							support
						</h2>
						<p>
							Our team provides fast assistance and
							<br />
							exclusive deals with short wait times,
							<br />
							and no obligation to purchase.
						</p>
						<div className="Inbound--Call">
							<div className="Inbound--Call--Text">
								Call us today on
							</div>
							<div className="Inbound--Call--Number">
								<SalesPhoneNumber />
							</div>
						</div>
						<div className="Inbound--Open">
							<OpeningTimes shortenDay={false}>
								{({ open, close, day, toDay }, index) => (
									<div key={index}>
										<span>
											{day} {toDay && `- ${toDay}`}
										</span>{' '}
										<span>
											{open} {close && `- ${close}`}
										</span>
									</div>
								)}
							</OpeningTimes>
						</div>
					</div>
					<Row className="u-mt--tiny u-mb--micro">
						<Column size={6}>
							<Button
								className="c-btn--mpd-grey"
								style={{ minWidth: '132px' }}
								renderAs="a"
								onClick={() => {
									globalThis.dataLayer.push({
										event: 'GTMEvent',
										eventCategory: 'Sales Enquiries',
										eventAction: 'View FAQ',
										eventLabel: '',
									});
								}}
								href="/help"
							>
								View FAQs
							</Button>
						</Column>
						<Column size={6} className="u-ta--right">
							<SalesPhoneNumber
								className="c-btn c-btn--mpd-grey"
								style={{ minWidth: '132px' }}
								onClick={trackPhoneNumberClick}
							>
								Call Us
							</SalesPhoneNumber>
						</Column>
					</Row>
				</Modal>
			</>
		);
	} else
		return (
			<>
				<CallUsLabel onClick={onClick} />

				<Modal
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
					title="Need Help?"
				>
					<div className="u-p--small u-pb--none">
						<div style={{ lineHeight: '1.2' }}>
							Sales Enquiries:
						</div>
						<div className="u-fz--display u-c--primary-text">
							{phoneNumber}
						</div>
						<p
							className="u-fz--base-small u-c--action-text u-my--tiny"
							style={{ lineHeight: '1.2' }}
						>
							<OpeningTimes>
								{({ open, close, day, toDay }, index) => (
									<div key={index}>
										<span>
											{open} {close && `- ${close}`}
										</span>{' '}
										<span>
											({day} {toDay && `- ${toDay}`})
										</span>
									</div>
								)}
							</OpeningTimes>
						</p>
						<hr className="u-my--small" />
						<Row className="u-mt--tiny u-mb--micro">
							<Column size={6}>
								<Button
									className="c-btn--mpd-grey"
									style={{ minWidth: '132px' }}
									renderAs="a"
									onClick={() => {
										globalThis.dataLayer.push({
											event: 'GTMEvent',
											eventCategory: 'Sales Enquiries',
											eventAction: 'View FAQ',
											eventLabel: '',
										});
									}}
									href="/help"
								>
									View FAQs
								</Button>
							</Column>
							<Column size={6} className="u-ta--right">
								<SalesPhoneNumber
									className="c-btn--mpd-grey"
									style={{ minWidth: '132px' }}
									onClick={trackPhoneNumberClick}
								>
									Call Us
								</SalesPhoneNumber>
							</Column>
						</Row>
					</div>
				</Modal>
			</>
		);
}
