import React from 'react';

function HandsetList({ handsets, getItemProps, isFetching, selectHandset }) {
	if (handsets.length === 0) return null;

	return (
		<>
			<div className="u-c--primary-text u-font-group--display u-fz--title-small u-fw--700 u-py--tiny u-px--small">
				Handsets
			</div>
			{handsets.slice(0, 10).map((handset, index) => (
				<a
					key={index}
					href={handset.url}
					className="item u-d--block u-font-group--display u-fz--title-small u-fw--700 u-py--tiny u-px--small"
					{...getItemProps({
						index: index,
						item: handset,
						onClick: e => {
							selectHandset(handset);
						},
					})}
				>
					{handset.title}
				</a>
			))}
			{!isFetching && handsets.length === 0 && (
				<div className="item u-fw--700 u-py--tiny u-px--small u-bgc--inverted">
					No handsets found.
				</div>
			)}
		</>
	);
}
function FaqList({ faqs, getItemProps, isFetching, selectFaq }) {
	if (faqs.length === 0) return null;
	return (
		<>
			<div className="u-c--primary-text u-font-group--display u-fz--title-small u-fw--700 u-py--tiny u-px--small">
				FAQs
			</div>
			{faqs.slice(0, 10).map((faq, index) => (
				<div
					key={index}
					className="item u-font-group--display u-fz--title-small u-fw--700 u-py--tiny u-px--small"
					{...getItemProps({
						index: index,
						item: faq,
						onClick: e => {
							selectFaq(faq);
						},
					})}
				>
					{faq.question}
					<div
						className="u-fz--caption"
						dangerouslySetInnerHTML={{
							__html:
								faq.answer
									.replace(/\r?\n|\r/, '')
									.substr(0, 120) + '...',
						}}
					/>
				</div>
			))}
			{!isFetching && faqs.length === 0 && (
				<div className="item u-fw--700 u-py--tiny u-px--small u-bgc--inverted">
					No FAQs found.
				</div>
			)}
		</>
	);
}

function SearchAutocomplete({
	getInputProps,
	getItemProps,
	isOpen,
	clearSelection,
	getToggleButtonProps,
	openMenu,
	// Custom props
	faqs,
	handsets,
	query,
	setQuery,
	isFetching,
	selectHandset,
	selectFaq,
	selectBestSeller,
	bestSellers,
}) {
	function inputOnChange(e) {
		// if (!e.target.value) return;
		setQuery(e.target.value);
	}

	const noResultsFound =
		query > 1 && faqs.length === 0 && handsets.length === 0;
	const showBestSellers =
		bestSellers.length > 0 && faqs.length === 0 && handsets.length === 0;

	return (
		<span className="c-input-container u-w--100 u-ta--left">
			<input
				type="text"
				className="c-input-text u-w--100 u-pl--large u-pr--giant u-fz--base-small u-fz--title-small--bp-large u-pl--small--bp-medium u-pr--small--bp-medium"
				{...getInputProps({
					placeholder: 'Search for products or advice',
					onChange: inputOnChange,
					onFocus: e => {
						bestSellers.length > 0 && openMenu();
						document.body.classList.add('search-active');
					},
					onBlur: () => {
						document.body.classList.remove('search-active');
					},
					autoComplete: 'off',
				})}
			/>
			{isOpen ? (
				<div
					role="button"
					tabIndex={0}
					id="clear-search"
					className="u-pos--absolute u-d--block u-h--100"
					style={{
						zIndex: 2,
						cursor: 'pointer',
						right: '3%',
						width: '45px',
						top: '0',
						bottom: '0',
					}}
					onClick={e => {
						e.preventDefault();
						clearSelection();
						setQuery('');
					}}
					onKeyPress={e => {
						e.preventDefault();
						clearSelection();
						setQuery('');
					}}
				>
					{isFetching ? (
						<div className="loading-spinner" aria-label="loading">
							<div className="bounce1"></div>
							<div className="bounce2"></div>
							<div className="bounce3"></div>
						</div>
					) : (
						<span className="icon-close u-fz--base u-fw--600 u-c--caption-text" />
					)}
				</div>
			) : (
				<span className="ico ico-search" />
			)}
			{isOpen && (
				<div className="results u-py--tiny u-bgc--inverted u-w--100 u-pos--absolute u-z--4 u-ov--auto">
					{noResultsFound && (
						<div className="item u-font-group--display u-fz--title-small u-fw--700 u-py--tiny u-px--small">
							No results found.
						</div>
					)}
					{showBestSellers && (
						<>
							<div className="u-c--primary-text u-font-group--display u-fz--title-small u-fw--700 u-py--tiny u-px--small">
								Popular Searches
							</div>

							{[
								{
									link:
										'https://www.mobilephonesdirect.co.uk/deals/apple-iphone-deals',
									name: 'Apple Deals',
								},
								{
									link:
										'https://www.mobilephonesdirect.co.uk/deals/samsung-phone-deals',
									name: 'Samsung Deals',
								},
								{
									link:
										'https://www.mobilephonesdirect.co.uk/deals/deals-under-20',
									name: 'Deals under £20',
								},
							].map((navItem, index) => (
								<a
									href={navItem.link}
									key={index}
									onClick={() => selectBestSeller(navItem)}
									className="item u-d--block u-font-group--display u-fz--title-small u-fw--700 u-py--tiny u-px--small"
								>
									{navItem.name}
								</a>
							))}
							<div className="u-c--primary-text u-font-group--display u-fz--title-small u-fw--700 u-py--tiny u-px--small">
								Popular Smartphones
							</div>
							{bestSellers.map((navItem, index) => (
								<a
									href={navItem.link}
									key={index}
									onClick={() => selectBestSeller(navItem)}
									className="item u-d--block u-font-group--display u-fz--title-small u-fw--700 u-py--tiny u-px--small"
								>
									{navItem.name}
								</a>
							))}
						</>
					)}
					<HandsetList
						selectHandset={selectHandset}
						handsets={handsets}
						getItemProps={getItemProps}
						isFetching={isFetching}
					/>
					<FaqList
						selectFaq={selectFaq}
						faqs={faqs}
						getItemProps={getItemProps}
						isFetching={isFetching}
					/>
				</div>
			)}
		</span>
	);
}
export { SearchAutocomplete };
