import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';

export function useDelayState<T = unknown>(
	initialValue: T,
	delay = 200
): [T, Dispatch<SetStateAction<T>>] {
	const [state, setState] = useState<T>(initialValue);
	const timeoutRef = useRef<number>();

	useEffect(() => {
		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [initialValue, delay]);

	const delayedSetState: Dispatch<SetStateAction<T>> = (
		value: SetStateAction<T>
	): void => {
		clearTimeout(timeoutRef.current);

		timeoutRef.current = window.setTimeout(() => {
			setState(value);
		}, delay);
	};

	return [state, delayedSetState];
}
