import React from 'react';
import ReactDOM from 'react-dom';

import { SearchBar } from './SearchBar';
import { CallUs, CallUsV2, MegaMenuBannerCallUs } from '../Common/Header/index';
import { getDataFromScriptById } from 'components/Common/get-data-from-script-by-id';
import { ScreenSizeProvider } from 'components/Common/use-screen-size';
import { CallUsBanner } from 'components/Common/Header/CallUsBanner';
import { PropositionBar } from 'components/Common/Header/PropositionBar/PropositionBar';

function getBestSellers() {
	try {
		const nav = getDataFromScriptById('best-sellers-data');
		const bestSellers = nav.desktop[0].children[0].children;
		return bestSellers;
	} catch (e) {
		// Best sellers should error if search element is present and no data is available
		// eslint-disable-next-line
		console.error(
			'Best sellers data not found for navigation search. ' + e.message
		);
		return [];
	}
}

const searchElements = document.querySelectorAll('.header-search');
let bestSellers = [];
// Search will be rendered on this page (not a minimal checkout header page)
if (searchElements.length > 0) {
	bestSellers = getBestSellers();
}
// Render both mobile and desktop versions if found
searchElements.forEach(element => {
	ReactDOM.render(<SearchBar bestSellers={bestSellers} />, element);
});

const callUsElements = document.querySelectorAll('.CallUsComponent');
callUsElements.forEach(element => {
	ReactDOM.render(
		<ScreenSizeProvider>
			<CallUs />
		</ScreenSizeProvider>,
		element
	);
});

const callUsElementsV2 = document.querySelectorAll('.CallUsComponentV2');
callUsElementsV2.forEach(element => {
	ReactDOM.render(
		<React.StrictMode>
			<CallUsV2 />
		</React.StrictMode>,
		element
	);
});
const callUsCheckoutElements = document.querySelectorAll(
	'.CallUsComponent--Checkout'
);
callUsCheckoutElements.forEach(element => {
	ReactDOM.render(
		<ScreenSizeProvider>
			<CallUs />
		</ScreenSizeProvider>,
		element
	);
});

const headerFeaturesMobileElement = document.querySelector(
	'#header-features-mobile'
);
if (headerFeaturesMobileElement) {
	ReactDOM.render(
		<CallUsBanner />,
		document.getElementById('header-features-mobile')
	);
}

const MegaMenuBanner = document.querySelector(
	'.MegaMenu--Inner--Asset--HelpAdvice'
);
if (MegaMenuBanner) {
	ReactDOM.render(<MegaMenuBannerCallUs />, MegaMenuBanner);
}

const propositionbar = document.getElementById('PropositionBar');
if (propositionbar)
	ReactDOM.render(
		<ScreenSizeProvider>
			<PropositionBar />
		</ScreenSizeProvider>,
		propositionbar
	);
