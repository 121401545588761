import { urlToRelative } from 'components/Common/url-to-relative';
import { Fetch, ResponseProps } from 'helpers/Fetch';
import { HttpMethod } from 'hooks/use-fetch';

export interface FAQ {
	question: string;
	answer: string;
}

export interface Handset {
	url: string;
	title: string;
}

interface SearchResults {
	faqs: FAQ[];
	handsets: Handset[];
}

/**
 * @param query search query to send to laravel
 * @param signal `AbortController` signal used to stop in-flight requests if a new request is sent
 */
export async function fetchSearchResults(
	query: string,
	signal?: AbortSignal
): Promise<ResponseProps<SearchResults>> {
	const url = new URL(globalThis.Router('handset.search', query).toString());
	url.searchParams.set('query', query);

	return Fetch<SearchResults>(urlToRelative(url.toString()), {
		method: HttpMethod.GET,
		signal,
	});
}
